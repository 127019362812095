import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='new-gallery-slides'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/58.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/59.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/60.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item>   
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/61.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/62.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/63.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/64.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/65.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/66.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item>  
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/67.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/68.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/69.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/70.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item>  
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/71.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item>  
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/72.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/73.jpeg"
          alt="Second slide"
        />      
      </Carousel.Item> 

    </Carousel>
  );
}

export default PowerCarousel;