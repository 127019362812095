import React from 'react';
import {Link} from 'react-router-dom'
import {FaTiktok, FaFacebookSquare,FaYoutube, FaTwitterSquare, FaInstagram} from 'react-icons/fa';
import {TfiYoutube} from 'react-icons/ti'
import { IoIosMusicalNote } from "react-icons/io";

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}



const Footer = () => {

  return (
    <section className='section-cover footer-cover' style={{ backgroundImage: `linear-gradient(#001621, #001621), url("./images/11.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
      <div className='footer-content'>

 



             <div className='footer-inner-content'>
                    <h3>Quick Links</h3>
                    <div className="footer-links-cover">
                        <div className='footer-link'>
                             <Link to='/' onClick={scrollFunc}>  <p><IoIosMusicalNote></IoIosMusicalNote></p> <p className='footer-a'>Home</p></Link> 
                        </div>

                         <div className='footer-link'>
                              <Link to='team' onClick={scrollFunc}> <p><IoIosMusicalNote></IoIosMusicalNote></p> <p className='footer-a'>Meet Our Team</p></Link> 
                        </div>
                         <div className='footer-link'>
                              <Link to='aga-foundation' onClick={scrollFunc}> <p><IoIosMusicalNote></IoIosMusicalNote></p> <p className='footer-a'>AGA Foundation</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='partners' onClick={scrollFunc}> <p><IoIosMusicalNote></IoIosMusicalNote></p> <p className='footer-a'>Our Partners</p></Link> 
                        </div> 
                        
                        <div className='footer-link'>
                              <Link to='donate' onClick={scrollFunc}> <p><IoIosMusicalNote></IoIosMusicalNote></p> <p className='footer-a'>Donate</p></Link> 
                        </div>   
                        <div className='footer-link'>
                              <Link to='gallery' onClick={scrollFunc}> <p><IoIosMusicalNote></IoIosMusicalNote></p> <p className='footer-a'>Gallery</p></Link> 
                        </div>                
                    </div>

             </div>

            

             {/* <div className='footer-inner-content'>
                    <h3>Our Services</h3>
                    <div className="footer-links-cover">
                      

                         <div className='footer-link'>
                              <Link to='telecom' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Telecom & Networking Services</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='power' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Power Line Design & Construction</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='road-construction' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Road Construction & Maintenance</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='support' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Support & Managed Services</p></Link> 
                        </div>
                          <div className='footer-link'>
                              <Link to='maintenance' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Preventive Maintenance</p></Link> 
                        </div>

                        <div className='footer-link'>
                            <Link to='emergency-response' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Emergency Response</p></Link> 
                        </div>

                         <div className='footer-link'>
                            <Link to='building-construction' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Building Construction</p></Link> 
                        </div>
                      
                      
                    </div>

             </div> */}

             <div className='footer-inner-content'>
<div className='footer-location'>
                      <h3>Our Address</h3>
                      <p>
                        Kyebando, Kampala City<br></br>
                       P. O. Box 103544, Kampala - Uganda

                        </p>
                        
                     

                    </div>

                   <div className='footer-contacts'>
                      <h3>Call Us On</h3>
                      <p>
                 +256789 415352<br></br> 
 +256705 471122  <br></br> 
+256774 760910 
                      
                      
                      </p>

                    </div>

                    
                      

             </div>


                   <div className='footer-inner-content'>


                    

                     <div className='footer-email'>
                      <h3>Write To Us At</h3>
                  
                   <p>
                  
                   info@amazinggraceacapella.org
                    </p>
                    
                      
                      </div>
                      <div className='footer-email'>
                             <Link to='inquiries' className='inquiries-link' onClick={scrollFunc}>Inquiries</Link>
                      </div>
                      <div className='footer-social-icons'>
                         <h3>Connect With Us On</h3>
                         <div className='footer-icons'>
                            <a href='https://www.youtube.com/@amazinggraceacapellaanglic3408' target="_blank" rel='noreferrer'>  <span>
                        <FaYoutube></FaYoutube>
                                 </span></a>
                                <a href='https://facebook.com/profile.php?id=100086098733358' target="_blank" rel='noreferrer'> <span>
<FaFacebookSquare></FaFacebookSquare>
                                 </span></a>
                          <a href='https://twitter.com/AGAAnglican' target="_blank" rel='noreferrer'>     <span>
<FaTwitterSquare></FaTwitterSquare>
                                 </span>
                                 </a>

                            <a href='https://vm.tiktok.com/ZM6CwwubW/' target="_blank" rel='noreferrer'>       <span>
<FaTiktok></FaTiktok>
                                 </span>  
                                 </a>
                                  <a href='https://instagram.com/amazinggraceacapellaangrican' target="_blank" rel='noreferrer'>       <span>
<FaInstagram></FaInstagram>
                                 </span>  
                                 </a>
                         </div>
                        
                        
                      </div>

             </div>

        
        
        
        </div>
        <div className='footer-bottom'>
            <p>    &copy; {new Date().getFullYear()} <span className="date"></span> Amazing Grace Acapella | Website Developed By <a href="https://bensitesug.com" target="_blank" className="footer-link-a" rel="noopener noreferrer">BenSites</a></p>
          </div>
    </section>
  )
}

export default Footer