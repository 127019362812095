import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='new-gallery-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/38.jpeg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/39.jpeg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/40.jpeg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/41.jpeg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/42.jpeg"
          alt="First slide"
        />

      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/43.jpeg"
          alt="First slide"
        />

      </Carousel.Item>
 
    </Carousel>
  );
}

export default PowerCarousel;