import React, {useEffect} from 'react'
import { FaEnvelope } from 'react-icons/fa';

const Team = () => {

useEffect(()=>{
document.title = 'Our Team | Amazing Grace Acapella';

},[]);

  return (
    <>
       <section className='section-cover team-cover'>
        
          <div className='content-container content-container-team'>
<h2 className="home-h2 home-h2-a">Our Team</h2>
            <div className="team-content">
                 <article className="team-member">


                      <div className="team-image">
                       <img src="./images/13.jpeg" alt="photo"  className="team-photo"/>
                      </div>
                       <div className="team-details">
                          <h3 className="team-h3">Andrew Bakkabulindi</h3>
                          <p className="team-p">Team Leader</p>
                          <p className="team-p team-roles">Bass, Baritone, Lead, Tenor & Alto</p>
                          <p className='team-p team-email'><FaEnvelope className='team-icon'></FaEnvelope>abakkabulindi@amazinggraceacapella.org</p>


                       </div>

                </article>
                <article className="team-member">


                      <div className="team-image">
                       <img src="./images/14.jpeg" alt="photo"  className="team-photo"/>
                      </div>
                       <div className="team-details">
                          <h3 className="team-h3">Lukwago Ronald Spinks</h3>
                          <p className="team-p">Programs/Projects Manager</p>
                          <p className="team-p team-roles">Baritone & Lead</p>
                          <p className='team-p team-email'><FaEnvelope className='team-icon'></FaEnvelope>rlukwago@amazinggraceacapella.org </p>
                       </div>

                </article>
                <article className="team-member">


                      <div className="team-image">
                       <img src="./images/15.jpeg" alt="photo"  className="team-photo"/>
                      </div>
                       <div className="team-details">
                          <h3 className="team-h3">Mwiine Resty (Ressie)</h3>
                          <p className="team-p">General Secretary</p>
                          <p className="team-p team-roles">Lead Vocalist & Soprano</p>
                         <p className='team-p team-email'><FaEnvelope className='team-icon'></FaEnvelope>rmwiine@amazinggraceacapella.org </p>
                       </div>

                </article>
                <article className="team-member">


                      <div className="team-image">
                       <img src="./images/16.jpeg" alt="photo"  className="team-photo"/>
                      </div>
                       <div className="team-details">
                          <h3 className="team-h3">Aggrey Samuel Kyalirizo</h3>
                          <p className="team-p">Finance Manager</p>
                          <p className="team-p team-roles">Alto, Tenor, Lead & Soprano</p>
                           <p className='team-p team-email'><FaEnvelope className='team-icon'></FaEnvelope>akyalirizo@amazinggraceacapella.org </p>
                       </div>

                </article>
                <article className="team-member">


                      <div className="team-image">
                       <img src="./images/17.jpeg" alt="photo"  className="team-photo"/>
                      </div>
                       <div className="team-details">
                          <h3 className="team-h3">Amos Muneza</h3>
                          <p className="team-p">Marketing Manager</p>
                          <p className="team-p team-roles">Baritone, Lead, Tenor & Alto</p>
                          <p className='team-p team-email'><FaEnvelope className='team-icon'></FaEnvelope>amugeza@amazinggraceacapella.org</p>
                       </div>

                </article>
               

       </div>
        </div>
        </section> 
    </>
  )
}

export default Team