import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='new-gallery-slides'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/44.jpeg"
          alt="Second slide"
        />
        
      </Carousel.Item>  
    </Carousel>
  );
}

export default PowerCarousel;