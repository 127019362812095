import Carousel from 'react-bootstrap/Carousel';

function Residential1() {
  return (
    <Carousel indicators={false} className='new-gallery-slides'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/101.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/102.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/103.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
     
       
    </Carousel>
  );
}

export default Residential1;