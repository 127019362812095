import Carousel from 'react-bootstrap/Carousel';

function Residential1() {
  return (
    <Carousel indicators={false} className='new-gallery-slides'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/45.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/46.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/47.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/48.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/49.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/50.jpeg"
          alt="Second slide"
        />   
      </Carousel.Item>
       
    </Carousel>
  );
}

export default Residential1;